import "../../Styles/data.scss";
import { useState } from "react";
import { useEffect, useRef } from "react";
import Pagination from "../Util/Pagination";
import Popup from "./Popup";
import WaveLoading from "../Util/WaveLoading";
import Button from "../Util/Button";
import GroupInput from "../Groups/GroupInput";
import { isMobile } from "react-device-detect";

export default function Data(props) {
  const jwt = require("jsonwebtoken");
  const [data, setData] = useState(null);
  const [head, setHead] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [isloading, setIsLoading] = useState(false);
  const [showing, setShowing] = useState(null);
  const [toggleAddMember, setToggleAddMember] = useState(false);
  const [selected, setSelected] = useState(null);
  const [editing, setEditing] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);

  const token = localStorage.getItem("gdfhgfhtkn");
  var decoded = jwt.decode(token);
  const level = decoded.Level.replace(" ", "");
  //  const level = decoded?.Level ? decoded.Level.replace(" ", "") : null;
  const levelname = decoded.LevelName;

  useEffect(() => {
    setIsLoading(true);
    let url = value
      ? `${props.url}/${level}/${levelname}/name/${value}/${(currentPage - 1) * 12}`
      : `${props.url}/${level}/${levelname}/${(currentPage - 1) * 12}`;
    fetch(url)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        if (data?.data?.length > 0) {
          const cols = Object.keys(data.data[0]);
          let d = [];
          cols.forEach((item) => {
            if (
              item.toLowerCase() !== "nationalid" &&
              item.toLowerCase() !== "user" &&
              item.toLowerCase() !== "createdat" &&
              item.toLowerCase() !== "updatedat" &&
              item.toLowerCase() !== "farmingtype" &&
              item.toLowerCase() !== "id"
            ) {
              d.push(item);
            }
          });
          if (isMobile) {
            setCount(2);
          } else {
            let c = d.length > 5 ? 5 : d.length;
            setCount(c);
          }
          setHead(d);
          setData(data);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [currentPage, refresh]);

  return (
    <div className="data">
      <div className="list">
        <div className="dtitle">
          <h3>{props.title}</h3>
          <div className="search">
            {showSearch && (
              <input
                // className="query"
                onChange={(e) => {
                  setError("");
                  setValue(e.target.value);
                }}
                placeholder="Search by Name"
                type="text"
              />
            )}
            <i
              className="fa fa-search "
              onClick={() => {
                value ? setRefresh(!refresh) : setShowSearch(!showSearch);
              }}
            ></i>
          </div>
        </div>
        <hr />
        <div
          style={{
            gridTemplateColumns: `4em repeat(${head ? count : 0},1fr)`,
          }}
          className="head"
        >
          <h4>No.</h4>
          {head &&
            head.length > 0 &&
            head.map((item, i) => {
              if (i < count) {
                return <h4 key={i}>{item}</h4>;
              }
            })}
        </div>
        {data &&
          data?.data?.length > 0 &&
          data?.data?.map((item, i) => {
            return (
              <Item
                setShowing={setShowing}
                key={i}
                index={i}
                data={item}
                head={head}
                count={count}
                page={currentPage}
              />
            );
          })}

        <div className="btns">
          {props.title.includes("Members") && (
            <i
              className="fa fa-plus newMember"
              title="Add new member"
              onClick={() => {
                setToggleAddMember(!toggleAddMember);
                setShowing(null);
              }}
            ></i>
          )}

          {data?.total && (
            <Pagination
              totalItems={data?.total}
              onPageChange={(v) => {
                setCurrentPage(v);
              }}
              currentPage={currentPage}
            />
          )}
        </div>
        {showing !== null && (
          <Popup
            showing={showing}
            editing={editing}
            setEditing={setEditing}
            setShowing={setShowing}
            data={data?.data}
            url={props.url}
          />
        )}
        {toggleAddMember && (
          <AddMember
            showing={showing}
            editing={editing}
            setEditing={setEditing}
            setToggleAddMember={setToggleAddMember}
            data={data?.data}
            url={props.url}
            gname={props.gname}
            gtype={props.gtype}
            setSelected={setSelected}
            selected={selected}
          />
        )}
      </div>
      {isloading && <WaveLoading />}
    </div>
  );
}

// const Popup = (props) => {
//   const [isLoading, setIsLoading] = useState(null);
//   const [cols, setCols] = useState(null);
//   const [cls, setCls] = useState(null);
//   const [data, setData] = useState(null);
//   const [index, setIndex] = useState(0);
//   const [active, setActive] = useState("Basic");

//   const [subcounty, setSubCounty] = useState(
//     new VectorLayer({ title: "Sub Counties" })
//   );
//   const [ward, setWard] = useState(new VectorLayer({ title: "Wards" }));
//   const [basemap, setBasemap] = useState(new TileLayer({ title: "Basemap" }));
//   const [graticule, setGraticule] = useState(
//     new Graticule({
//       strokeStyle: new Stroke({
//         color: "rgba(0,0,0,0.5)",
//         width: 2,
//         lineDash: [0.5, 8],
//       }),
//       showLabels: true,
//       wrapX: false,
//       title: "Grid",
//     })
//   );
//   const [map, setMap] = useState(null);
//   const mapElement = useRef();
//   mapElement.current = map;

//   useEffect(() => {
//     if (active == "Map") {
//       basemap.setSource(
//         new XYZ({
//           url:
//             "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}" +
//             "?access_token=pk.eyJ1IjoiZ2F0aG9nbzEiLCJhIjoiY2t0djhndnB4MGkzdDJucDg2bW5uNXNrcyJ9.mnbTMXxDrdYnTrb8Gr7_MA",
//           crossOrigin: "anonymous",
//         })
//       );
//       const initialMap = new Map({
//         target: mapElement.current,
//         layers: [basemap, subcounty, ward, graticule],
//         view: new View({
//           projection: "EPSG:4326",
//           center: [36.45, -0.1],
//           zoom: 12,
//           maxZoom: 20,
//         }),
//         controls: defaultControls().extend([
//           new ZoomToExtent({
//             extent: [34.36168, 0.41839, 35.06887, 1.14702],
//           }),
//           new ScaleLine({
//             units: "metric",
//             bar: false,
//             text: "Scale",
//           }),
//         ]),
//       });

//       setMap(initialMap);
//     }
//   }, [active]);

//   useEffect(() => {
//     if (active == "Map" && map) {
//       fetch(`/api/farmeraddress/${props.data[props.showing].NationalID}`)
//         .then((res) => {
//           if (res.ok) return res.json();
//           else throw Error("");
//         })
//         .then((data) => {

//           let features = new Array(data.length);
//           data.map((item, i) => {
//             features[i] = new Feature({
//               geometry: new Point([
//                 parseFloat(item.Longitude),
//                 parseFloat(item.Latitude),
//               ]),
//             });
//             const obj = Object.entries(item);
//             obj.map((dt) => {
//               features[i].values_[dt[0]] = dt[1];
//             });
//           });

//           const vs = new VectorSource({
//             features: features,
//             format: new GeoJSON(),
//           });
//           const f = new WebGLPointsLayer({
//             title: "farmers",
//             style: {
//               symbol: {
//                 symbolType: "circle",
//                 size: 20,
//                 color: "#FFA500",
//                 rotateWithView: false,
//               },
//               stroke: {
//                 color: "#319FD3",
//                 width: 1,
//               },
//             },
//             disableHitDetection: false,
//             source: vs,
//           });

//           map.addLayer(f);

//           map.getView().fit(vs.getExtent(), {
//             padding: [100, 100, 100, 100],
//           });

//           return () => {
//             map.setTarget(null);
//           };
//         })
//         .catch((e) => {});
//     }
//   }, [active, map]);

//   useEffect(() => {
//     setIsLoading(true);
//     setCols(null);
//     setCls(null);
//     setData(null);
//     if (active === "Basic") {
//       if (props.showing !== null) {
//         const cols = Object.keys(props.data[props.showing]);
//         let d = [];
//         cols.forEach((item) => {
//           if (item.toLowerCase() !== "geom" && item.toLowerCase() !== "id") {
//             d.push(item);
//           }
//         });
//         setCols(d);
//       }
//       setIsLoading(false);
//     } else {
//       let d = "farmeraddress";
//       switch (active) {
//         case "Address":
//           d = "farmeraddress";
//           break;
//         case "Map":
//           d = "farmeraddress";
//           break;
//         case "Farm":
//           d = "farmerresources";
//           break;
//         case "Groups":
//           d = "farmergroups/farmerid";
//           break;
//         case "Value Chains":
//           d = "farmerdetails/valuechains";
//           break;
//         default:
//           setActive("Basic");
//           break;
//       }

//       fetch(`/api/${d}/${props.data[props.showing].NationalID}`)
//         .then((res) => {
//           if (res.ok) return res.json();
//           else throw Error("");
//         })
//         .then((data) => {
//           setIsLoading(false);
//           if (data.length > 0) {
//             setData(data);
//             const cols = Object.keys(data[0]);
//             let d = [];
//             cols.forEach((item) => {
//               if (item.toLowerCase() !== "geom") {
//                 d.push(item);
//               }
//             });
//             setCls(d);
//           } else if (data) {
//           }
//         })
//         .catch((e) => {
//           setIsLoading(false);
//         });
//     }
//   }, [active]);

//   useEffect(() => {
//     if (data) {
//       const cols = Object.keys(data[index]);
//       let d = [];
//       cols.forEach((item) => {
//         if (item.toLowerCase() !== "geom") {
//           d.push(item);
//         }
//       });
//       setCls(d);
//     }
//   }, [index]);

//   const Bar = (params) => {
//     return (
//       <p
//         onClick={() => {
//           setActive(params.txt);
//         }}
//         className={active === params.txt ? "active" : ""}
//       >
//         {params.txt}
//       </p>
//     );
//   };

//   return (
//     <div data-aos="fade-left" className="popup">
//       <div className="container">
//         <div className="dets">
//           <h3>{props?.data[props.showing]?.Name.replace(/\b\w/g, match => match.toUpperCase())/*Capitalize each word (names)*/}</h3>
//           <i
//             onClick={() => {
//               props.setShowing(null);
//             }}
//             className="fa fa-times"
//           ></i>
//         </div>
//         <div className="bar">
//           <Bar txt="Basic" />
//           <Bar txt="Map" />
//           <Bar txt="Address" />
//           <Bar txt="Farm" />
//           <Bar txt="Groups" />
//           <Bar txt="Value Chains" />
//         </div>
//         {active == "Map" ? (
//           <div className="ppmap">
//             <div ref={mapElement} className="pmap"></div>
//             {/* <div id="popup" className="ol-popup">
//               <div id="popup-content"></div>
//             </div> */}
//           </div>
//         )
//         :
//         (<div className="content">
//           {cols &&
//             cols.map((item, i) => {
//               return (
//                 <p key={i}>
//                   <b>{item.toLowerCase() === "createdat" ? "Mapped On" :
//               item.toLowerCase() === "updatedat" ? "Updated On" : item}</b>  { item.toLowerCase() === "createdat" ||
//               item.toLowerCase() === "updatedat" ? props.data[props.showing][item].split("T")[0] 
//               : props.data[props.showing][item]}
//                 </p>
//               );
//             })}
//           {cls &&
//             cls.map((item, i) => {
//               return (
//                 <p key={i}>
//                   <b>{item}</b> {data[index][item]}
//                 </p>
//               );
//             })}
//           {isLoading && <WaveLoading />}
//           <div className="tally">
//             {data &&
//               data.length > 1 &&
//               data.map((item, i) => {
//                 return (
//                   <p
//                     className={i === index ? "active" : ""}
//                     onClick={() => {
//                       setIndex(i);
//                     }}
//                     key={i}
//                   >
//                     {i + 1}
//                   </p>
//                 );
//               })}
//           </div>
//         </div>)}
//       </div>
//     </div>
//   );
// };

const AddMember = (props) => {
  const [searchFarmers, setsearchFarmers] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isError, setIsError] = useState(null);

  const [body, setBody] = useState(null);

  useEffect(() => {
    props.selected &&
      setBody({
        FarmerID: props.selected.NationalID,
        Name: props.gname,
        Type: props.gtype,
      });
  }, [props.selected]);

  const List = (params) => {
    return (
      <div
        onClick={() => {
          props.setSelected(params.item);
        }}
        className="s_list"
      >
        <p>
          {params.item.Name} - {params.item.Phone}
        </p>
      </div>
    );
  };

  function searchFarmer(gname, q) {
    fetch(`/api/farmerdetails/seachbynid/${q}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error();
      })
      .then((data) => {
        setsearchFarmers(data);
      })
      .catch((e) => {});
  }

  function addFarmerToGroup() {
    fetch(`/api/farmergroups`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error();
      })
      .then((data) => {
        setSuccess(data.success);
        setIsError(data.error);
      })
      .catch((e) => {});
  }

  return (
    <div data-aos="fade-left" className="popup">
      <div className="container">
        <div className="dets">
          <h3>{props?.data[props.showing]?.Name}</h3>
          <i
            onClick={() => {
              props.setToggleAddMember(false);
              props.setSelected(null);
            }}
            className="fa fa-times"
          ></i>
        </div>

        {props.selected ? (
          <div className="confirm">
            {success && <p className="success">{success}</p>}
            {isError && <p className="isError">{isError}</p>}
            <p>
              Confirm to add {props.selected.Name} to{" "}
              {props.gname.replaceAll("%20", " ")}?
            </p>
            <i
              className="newMember"
              onClick={() => {
                if (success || isError) {
                  props.setToggleAddMember(false);
                  setSuccess(null);
                  setIsError(null);
                } else {
                  addFarmerToGroup();
                }
              }}
            >
              Okay
            </i>
          </div>
        ) : (
          <div className="search">
            <p>Add a member to {props.gname.replaceAll("%20", " ")}</p>
            <GroupInput
              type="number"
              handleChange={(e) => {
                searchFarmer(props.gname, e);
              }}
              label="Search ID Number"
              hint="Type to search"
            />
            <div className="search_list">
              {searchFarmers?.map((item, i) => {
                return (
                  <List key={i} item={item} setSelected={props.setSelected} />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Item = (props) => {
  const cl = props.index % 2 == 0 ? "white" : "#60606010";

  return (
    <div
      style={{
        gridTemplateColumns: `4em repeat(${props.head ? props.count : 0}, 1fr)`,
        backgroundColor: cl,
      }}
      className="row"
      onClick={() => {
        props.setShowing(props.index);
      }}
    >
      <p>{(props.page-1) * 12 + props.index + 1}</p>
      {props.head.map((item, i) => {
        if (i < props.count) { /*Capitalize each word*/
          return <p key={i}>{props.data[item].replace(/\b\w/g, match => match.toUpperCase())}</p>;
        }
      })}
    </div>
  );
};
