import "../../Styles/documents.scss";
import AccordionBody from "../Util/AccordionBody";
import { useEffect, useState } from "react";
import Pagination from "../Util/Pagination"; 

export default function Documents(props) {
  const [documents, setDocuments] = useState();
  const [show, setShow] = useState(false);
  const [offset, setOffset] = useState(0);
  const [showing, setShowing] = useState(true);
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  const [arrow, setArrow] = useState("fa fa-angle-up");

  const toggle = () => {
    setShow(!show);
    if (show) {
      setArrow("fa fa-angle-down");
    } else setArrow("fa fa-angle-up");
  };

  useEffect(() => {
    fetch(`/api/documents/paginated/all/${offset}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch messages!!!");
        }
        return res.json();
      })
      .then((data) => {
        setDocuments(data);
      })
      .catch((err) => {});
  }, []);

  return (
        <div className="documents">
          <div className="list">
            <h3>Documents</h3>
            <hr />
            <div className="content">
              {documents &&
                documents.data.map((item, index) => {
                  return (
                    <AccordionBody
                      item={item}
                      key={index}
                      date={item.Date.split("T")[0]}
                      description={
                        item.Description.length > 30 ? item.Description : ""
                      }
                      file={item.File}
                    />
                  );
                })}
            </div>

            {documents && (
              <>
                <br></br>
                <Pagination
                  page={offset}
                  setOffset={setOffset}
                  total={documents.total}
                />
              </>
            )}
          </div>
        </div>
  );
}
