import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper.min.css";
// import "swiper/modules/pagination/pagination.min.css";
import lg1 from "../../assets/imgs/lg1.png";
import lg2 from "../../assets/imgs/lg2.jpg";
import lg3 from "../../assets/imgs/lg3.jpg";
import lg4 from "../../assets/imgs/lg4.jpg";
import lg5 from "../../assets/imgs/lg5.jpg";

import SwiperCore, {  } from "swiper";
SwiperCore.use([]);

export default function RightPanel(props) {
  const content = [lg1, lg2, lg3, lg4, lg5];
  return (
    <div className="right_panel">
      <Swiper
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        loop={true}
        grabCursor={true}
        pagination={true}
        // modules={[Pagination]}
        className="mySwiper"
      >
        {content.map((item, i) => {
          return (
            <SwiperSlide key={i}>
              <img src={item} alt="" />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
