import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import "../../Styles/newstats.scss";
import { FiRefreshCcw } from "react-icons/fi";
import GaugeChart from "react-gauge-chart";
import { FaRadiation, FaLeaf } from "react-icons/fa";
import { FaUserGroup, FaBuilding } from "react-icons/fa6";
import { PiCow } from "react-icons/pi";
import { BsImages, BsFiletypeCsv } from "react-icons/bs";
import html2canvas from "html2canvas";

import CustomPieChart from "./CustomPieChart";
import CustomBarChart from "./CustomBarChart";

export default function NewStats() {
    const [refresh, setRefresh] = useState(false);
    const [aspect, setAspect] = useState(1.5);
    const [aspect1, setAspect1] = useState(1.5);
    const [projects, setProjects] = useState(null);
    const [data, setData] = useState(null);

    useLayoutEffect(() => {
        const { width, height } = p1ref.current.getBoundingClientRect();

        // setAspect(width / height);
        setAspect1(width / (height * 0.8));
    }, []);

    const p1ref = useRef();
    const p2ref = useRef();
    const p3ref = useRef();
    const p4ref = useRef();
    const p5ref = useRef();
    const p6ref = useRef();

    const jwt = require("jsonwebtoken");

    const token = localStorage.getItem("gdfhgfhtkn");
    var decoded = jwt.decode(token);
    const level = decoded?.Level ? decoded.Level.replace(" ", "") : null;

    const levelname = decoded?.LevelName;

    useEffect(() => {
        fetch(`/api/farmerdetails/all/stats/${level}/${levelname}`)
        .then((res) => {
            if (res.ok) return res.json();
            else throw Error("");
        })
        .then((data) => {
            console.log(data);
            setData(data);
        })
        .catch((e) => {});
    }, []);

    useEffect(() => {
        fetch(`/api/farmerdetails/charts/${level}/${levelname}`)
        .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
        })
        .then((data) => {
            console.log(data);
        setProjects(data);
        })
        .catch((e) => {});
    }, []);

    const handleDownloadImage = async (printRef) => {
        const element = printRef.current;
        const canvas = await html2canvas(element);
    
        const data = canvas.toDataURL("image/jpg");
        const link = document.createElement("a");
    
        if (typeof link.download === "string") {
          link.href = data;
          link.download = "image.jpg";
    
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          window.open(data);
        }
    };

    const saveData = (data) => {
        if (data.length > 0) {
          let rows = [];
          rows.push(Object.keys(data[0]));
          data.map((item) => {
            rows.push(Object.values(item));
          });
          let csvContent =
            "data:text/csv;charset=utf-8," +
            rows.map((e) => e.join(",")).join("\n");
    
          var encodedUri = encodeURI(csvContent);
          var link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "data.csv");
          document.body.appendChild(link);
          link.click();
        }
    };

    return (
      <div className="stats">
        <div className="welcome">
          <div>
            <h1>Farmers Portal</h1>
          </div>
          <FiRefreshCcw
            onClick={() => {
              setRefresh(!refresh);
            }}
            className="refresh"
          />
        </div>
        <div className="taskstats">
          <div className="left">
            <div className="bar">
              <div></div>
              <h2>Farmer Groups</h2>
            </div>
            <div className="outer">
              <div className="ll">
                <div className="section">
                  <div className="single">
                    <FaUserGroup className="ts" />
                    <div>
                      <h4>{data ? data?.CIG[0].total.toFixed(0) : 0}</h4>
                      <p>Common Interest Groups</p>
                    </div>
                  </div>
                </div>
                <div className="section">
                  <div className="single">
                    <FaRadiation className="ts" />
                    <div>
                      <h4>{data ? data?.PO[0].total.toFixed(0) : 0}</h4>
                      <p>Producer Organizations</p>
                    </div>
                  </div>
                </div>
                <div className="section">
                  <div className="single">
                    <FaBuilding className="ts" />
                    <div>
                      <h4>{data ? data?.SACCO[0].total.toFixed(0) : 0}</h4>
                      <p>SACCO's</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section">
                <h3 title="against all farmers">Farmers in groups</h3>
                <GaugeChart
                  id="gauge-chart1"
                  nrOfLevels={30}
                  arcsLength={[0.3, 0.3, 0.3, 0.3]}
                  colors={["#5BE12C", "#F5CD19", "#EA4228"]}
                  percent={(
                    (parseInt(data ? data?.CIG[0].total.toFixed(0) : 0) +
                      parseInt(data ? data?.SACCO[0].total.toFixed(0) : 0) +
                      parseInt(data ? data?.PO[0].total.toFixed(0) : 0)) /
                    parseInt(data ? data?.Farmers[0].total.toFixed(0) : 0)
                  ).toFixed(2)}
                  arcPadding={0.02}
                  textColor="gray"
                />
              </div>
            </div>
          </div>
          <div className="right">
            <div className="bar">
              <div></div>
              <h2>Land Acreage</h2>
            </div>

            <div className="outer">
              <div className="ll">
                <div className="section">
                  <div className="single">
                    <PiCow className="ts" />
                    <div>
                      <h4>{data ? data?.LivestockA[0].total.toFixed(0) : 0}</h4>
                      <p>Livestock Acrerage</p>
                    </div>
                  </div>
                </div>
                <div className="section">
                  <div className="single">
                    <FaLeaf className="ts" />
                    <div>
                      <h4>
                        {data?.CropA[0]?.total
                          ? data?.CropA[0]?.total.toFixed(0)
                          : 0}
                      </h4>
                      <p>Crop Acrerage</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section">
                <h3>Land in use</h3>
                <GaugeChart
                  id="gauge-chart1"
                  nrOfLevels={30}
                  arcsLength={[0.3, 0.3, 0.3, 0.3]}
                  colors={["#5BE12C", "#F5CD19", "#EA4228"]}
                  percent={(
                    (parseInt(data ? data?.LivestockA[0].total.toFixed(0) : 0) +
                      parseInt(
                        data?.CropA[0]?.total
                          ? data?.CropA[0]?.total.toFixed(0)
                          : 0
                      )) /
                    parseInt(data ? data?.TotalA[0].total.toFixed(0) : 0)
                  ).toFixed(2)}
                  arcPadding={0.02}
                  textColor="gray"
                />
              </div>
            </div>
          </div>
        </div>


        <div className="distributions">
          <div className="bar">
            <div></div>
            <h2>Distribution and status</h2>
          </div>
          <div className="div3equal">
            <div className="section" ref={p1ref}>
              <div className="div1auto">
                <h3>Farming Type</h3>
                <div className="div2equal">
                  <BsImages
                    color="#028e37"
                    onClick={() => {
                      handleDownloadImage(p1ref);
                    }}
                  />
                  <BsFiletypeCsv
                    color="#028e37"
                    onClick={() => {
                      saveData(projects?.Type);
                    }}
                  />
                </div>
              </div>
              {projects && (
                <CustomPieChart data={projects?.Type} aspect={aspect} />
              )}
            </div>
            <div className="section" ref={p2ref}>
              <div className="div1auto">
                <h3>Age Distribution</h3>
                <div className="div2equal">
                  <BsImages
                    color="#028e37"
                    onClick={() => {
                      handleDownloadImage(p2ref);
                    }}
                  />
                  <BsFiletypeCsv
                    color="#028e37"
                    onClick={() => {
                      saveData(projects?.Year);
                    }}
                  />
                </div>
              </div>
              {projects && (
                <CustomPieChart data={projects?.Year} aspect={aspect} />
              )}
            </div>
            <div className="section" ref={p3ref}>
              <div className="div1auto">
                <h3>Gender Distribution</h3>
                <div className="div2equal">
                  <BsImages
                    color="#028e37"
                    onClick={() => {
                      handleDownloadImage(p3ref);
                    }}
                  />
                  <BsFiletypeCsv
                    color="#028e37"
                    onClick={() => {
                      saveData(projects?.Expense);
                    }}
                  />
                </div>
              </div>
              {projects && (
                <CustomPieChart data={projects?.Expense} aspect={aspect} />
              )}
            </div>
          </div>
        </div>

        <div className="locations">
          <div className="outer">
            <div className="bar">
              <div></div>
              <h2>Sub county Distribution</h2>
            </div>
            <div className="section" ref={p4ref}>
                <div className="div1auto">
                    <h3></h3>
                    <div className="div2equal">
                        <BsImages
                        color="#028e37"
                        onClick={() => {
                            handleDownloadImage(p4ref);
                        }}
                        />
                        <BsFiletypeCsv
                        color="#028e37"
                        onClick={() => {
                            saveData(projects?.Expense);
                        }}
                        />
                    </div>
                </div>
                {projects && (
                    <CustomBarChart data={projects?.SubCounty} aspect={2.5} />
                )}
            </div>
          </div>
          
          <div className="outer">
            <div className="bar">
                <div></div>
                <h2>Ward distribution</h2>
            </div>
            <div className="section" ref={p5ref}>
                <div className="div1auto">
                    <h3></h3>
                    <div className="div2equal">
                        <BsImages
                        color="#028e37"
                        onClick={() => {
                            handleDownloadImage(p4ref);
                        }}
                        />
                        <BsFiletypeCsv
                        color="#028e37"
                        onClick={() => {
                            saveData(projects?.Expense);
                        }}
                        />
                    </div>
                </div>
                {projects && (
                    <CustomBarChart data={projects?.Ward} aspect={2.5} />
                )}
            </div>
          </div>
        </div>

        <div className="valuechains">
          <div className="bar">
            <div></div>
            <h2>Value Chains</h2>
          </div>
          <div className="section" ref={p6ref}>
            <div className="div1auto">
              <h3></h3>
              <div className="div2equal">
                <BsImages
                  color="#028e37"
                  onClick={() => {
                    handleDownloadImage(p6ref);
                  }}
                />
                <BsFiletypeCsv
                  color="#028e37"
                  onClick={() => {
                    saveData(projects?.Type);
                  }}
                />
              </div>
            </div>
            {projects && (
              <CustomBarChart data={projects?.Department} aspect={2.5} />
            )}
          </div>
        </div>
      </div>
    );
};