import { useEffect, useState } from "react";
import Loading from "../Util/Loading";
import Pagination from "../Util/Pagination";
import "../../Styles/valuechain.scss";
import WaveLoading from "../Util/WaveLoading";
import Group from "./Group";
import { isMobile } from "react-device-detect";
import VCData from "./VCData";
import VCMap from "./VCMap";

export default function ValueChain(props) {
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [head, setHead] = useState(null);
  const [count, setCount] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [showing, setShowing] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);
  const [dataURL, setDataURL] = useState(null);

  useEffect(() => {
    let dataurl = value
      ? `/api/farmeraddress/vcdata/paginated/${props.vc.replace(
          " ",
          ""
        ).replace(
          ")",
          ""
        ).replace(
          "(",
          ""
        ).replace(
          "&",
          ""
        ).replace(
          "%20",
          ""
        ).replace(
          "%20",
          ""
        )}/${value}/${offset}`
      : `/api/farmeraddress/vcdata/paginated/${props.vc.replace(
          " ",
          ""
        ).replace(
          ")",
          ""
        ).replace(
          "(",
          ""
        ).replace(
          "&",
          ""
        ).replace(
          "%20",
          ""
        ).replace(
          "%20",
          ""
        )}/null/`;
    setDataURL(dataurl);
    setLoading(true);
  }, [offset, refresh]);

  return (
    <div className="valuechain">
      {dataURL && (
        <VCData
          url={dataURL}
          title={props.vc.replace(
            "%20",
            " "
          ).replace(
            "&%20",
            " & "
          )}
          setRefresh={setRefresh}
          refresh={refresh}
          setValue={setValue}
          value={value}
        />
      )}
    </div>
  );
}

const Item = (props) => {
  const cl = props.index % 2 == 0 ? "white" : "#60606010";

  return (
    <div
      style={{
        gridTemplateColumns: `repeat(${props.head ? props.count : 0},1fr)`,
        backgroundColor: cl,
      }}
      className="row"
      onClick={() => {
        props.setShowing(props.index);
      }}
    >
      {props.head?.map((item, i) => {
        if (i < props.count) {
          return <p key={i}>{props.data[item]}</p>;
        }
      })}
    </div>
  );
};
